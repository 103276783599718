import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 2560.000000 2560.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,2560.000000) scale(0.100000,-0.100000)">
					<path d="M9000 17143 c-261 -40 -464 -115 -633 -233 -114 -79 -239 -196 -310
-290 -23 -30 -45 -56 -49 -58 -5 -2 -8 -8 -8 -13 0 -5 -17 -37 -38 -71 -79
-130 -123 -240 -165 -413 -21 -87 -22 -112 -27 -828 -3 -407 -2 -743 1 -748 3
-5 1513 -10 3685 -11 l3679 -3 0 -935 0 -935 -1777 -3 -1778 -2 0 -2060 0
-2060 2418 3 2417 2 93 23 c211 49 383 129 552 255 67 50 208 189 240 237 13
19 27 37 31 40 28 21 116 180 179 325 25 58 63 212 80 325 8 54 10 927 8 3175
l-3 3100 -22 90 c-33 136 -58 206 -113 320 -132 272 -350 493 -620 630 -112
57 -305 114 -453 134 -55 8 -1191 11 -3720 10 -2003 -1 -3653 -3 -3667 -6z"/>
					<path d="M10202 7643 l3 -377 65 -1 c36 -1 71 0 78 3 9 3 12 45 12 164 0 172
7 200 54 232 28 18 107 22 136 6 39 -21 45 -50 50 -230 l5 -175 75 0 75 0 0
200 c0 189 -1 202 -23 245 -36 70 -89 102 -177 108 -79 5 -136 -9 -169 -40
l-21 -19 -5 128 -5 128 -78 3 -77 3 2 -378z"/>
					<path d="M11985 8008 c-3 -7 -4 -177 -3 -376 l3 -364 68 -1 c37 0 72 4 77 9 7
7 9 143 8 375 l-3 364 -73 3 c-52 2 -74 -1 -77 -10z"/>
					<path d="M7920 7625 l0 -355 80 0 80 0 0 130 0 130 165 0 165 0 0 65 0 65
-165 0 -165 0 0 95 0 95 188 2 187 3 0 60 0 60 -267 3 -268 2 0 -355z"/>
					<path d="M10917 7934 c-4 -4 -7 -34 -7 -66 l0 -58 -39 0 c-44 0 -47 -5 -47
-75 l1 -50 42 -3 41 -3 4 -153 c3 -143 4 -155 28 -189 47 -69 158 -96 261 -64
44 13 44 18 14 90 -15 36 -16 36 -59 31 -36 -5 -47 -3 -65 15 -19 19 -21 32
-21 146 l0 125 65 0 65 0 0 65 0 65 -65 0 -64 0 -3 63 -3 62 -70 3 c-39 1 -74
0 -78 -4z"/>
					<path d="M8577 7814 c-4 -4 -7 -128 -7 -276 l0 -268 74 0 74 0 4 158 c5 206
23 240 133 252 l50 5 0 65 0 65 -48 -2 c-33 -1 -61 -10 -88 -27 -21 -14 -41
-26 -43 -26 -3 0 -6 12 -8 28 -3 26 -5 27 -68 30 -36 2 -69 0 -73 -4z"/>
					<path d="M9134 7800 c-97 -38 -163 -129 -171 -235 -7 -76 9 -133 50 -187 64
-83 144 -118 272 -118 71 0 155 31 189 69 l20 22 -41 40 -40 41 -41 -21 c-48
-25 -127 -28 -176 -7 -34 14 -79 62 -73 79 1 5 90 10 202 10 l200 2 3 40 c9
105 -48 209 -142 257 -60 31 -184 35 -252 8z m170 -104 c37 -16 76 -60 76 -87
0 -18 -10 -19 -130 -19 -110 0 -130 2 -130 15 0 29 34 68 75 86 48 22 66 23
109 5z"/>
					<path d="M9750 7805 c-86 -27 -135 -96 -127 -179 4 -41 11 -55 40 -82 38 -35
74 -48 182 -65 79 -13 111 -35 100 -68 -13 -41 -132 -47 -225 -12 -30 12 -58
21 -61 21 -12 0 -51 -102 -42 -110 39 -37 226 -60 323 -40 83 18 146 72 155
135 16 101 -40 162 -167 184 -122 22 -153 34 -156 63 -2 20 4 28 31 42 53 24
120 19 209 -19 13 -5 23 5 43 45 15 29 23 56 18 60 -18 18 -135 40 -204 39
-41 0 -94 -7 -119 -14z"/>
					<path d="M11480 7809 c-65 -20 -124 -72 -163 -144 -31 -57 -31 -192 0 -251 51
-95 159 -154 283 -154 80 0 165 27 200 65 20 22 21 25 7 39 -8 9 -25 28 -37
43 l-21 27 -45 -22 c-52 -25 -128 -29 -178 -8 -33 14 -79 62 -74 78 2 5 93 10
203 11 l200 2 3 50 c7 98 -44 188 -135 242 -38 22 -60 27 -127 30 -45 1 -97
-2 -116 -8z m157 -114 c22 -9 45 -28 56 -46 33 -58 30 -59 -118 -59 l-135 0
19 38 c23 45 40 60 78 72 42 12 59 12 100 -5z"/>
					<path d="M12417 7800 c-73 -28 -112 -64 -146 -131 -66 -133 -26 -285 94 -361
110 -69 287 -63 376 12 l34 29 -41 41 -40 41 -45 -20 c-80 -37 -177 -23 -228
32 -42 46 -31 49 184 50 l200 2 -1 72 c0 61 -4 78 -30 119 -35 57 -99 109
-152 124 -57 15 -151 11 -205 -10z m166 -104 c39 -16 63 -42 72 -78 l7 -28
-131 0 c-153 0 -158 5 -95 88 5 7 79 31 97 32 10 0 32 -6 50 -14z"/>
					<path d="M13100 7811 c-77 -24 -148 -80 -183 -146 -18 -32 -22 -57 -22 -125 0
-77 3 -90 31 -135 39 -65 111 -119 178 -135 67 -16 170 -9 217 14 46 21 111
85 107 104 -2 7 -27 27 -57 43 l-54 30 -21 -24 c-42 -50 -112 -62 -176 -29
-94 46 -93 222 0 269 31 16 104 17 133 1 12 -6 32 -23 44 -37 l22 -25 43 23
c68 38 74 45 55 75 -48 73 -118 106 -220 105 -40 0 -83 -4 -97 -8z"/>
					<path d="M13683 7799 c-57 -22 -113 -73 -146 -134 -18 -32 -22 -57 -22 -125 0
-75 3 -91 27 -132 62 -104 162 -154 293 -146 115 8 211 73 253 172 24 55 26
140 6 198 -27 75 -104 151 -174 172 -73 22 -173 20 -237 -5z m197 -124 c62
-32 86 -120 56 -205 -37 -104 -209 -107 -252 -5 -33 80 -7 180 56 212 34 17
105 16 140 -2z"/>
					<path d="M14207 7814 c-11 -12 -9 -544 3 -545 6 0 37 0 70 0 33 0 65 0 70 0 6
1 10 62 10 161 0 172 7 203 52 234 27 19 106 20 130 2 31 -23 38 -65 38 -231
0 -121 3 -164 13 -167 6 -2 42 -2 80 0 l67 4 0 161 c0 137 3 166 18 188 33 50
49 59 100 59 40 0 54 -5 75 -26 35 -35 39 -61 35 -237 -2 -93 1 -147 7 -148
59 -6 95 -7 118 -2 l28 5 -3 208 -3 208 -30 44 c-17 25 -49 53 -75 66 -38 20
-56 23 -120 19 -76 -4 -117 -18 -157 -56 -28 -26 -26 -26 -60 5 -39 36 -87 54
-148 54 -60 0 -111 -15 -139 -42 -27 -25 -34 -23 -38 10 -3 26 -5 27 -68 30
-36 2 -69 0 -73 -4z"/>
					<path d="M16110 7451 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z" />
					<path d="M16986 4644 c-3 -9 -6 -553 -6 -1210 0 -961 2 -1196 13 -1200 7 -2
1050 -5 2317 -7 l2305 -2 3 -1113 2 -1112 1325 0 1325 0 2 1112 3 1113 663 3
662 2 0 1215 0 1215 -4304 0 c-3915 0 -4304 -1 -4310 -16z"/>
				</g>
			</svg>
		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
